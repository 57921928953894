require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
// require('channels')
require("trix")
require("@rails/actiontext")

require('inter-ui')

import './web-player.js'

import '@fortawesome/fontawesome-free/css/all'
import 'frappe-charts/dist/frappe-charts.min'
import 'flag-icons/css/flag-icons.min'

import './stylesheets/tailwind'
import './stylesheets/choices'
import './stylesheets/choices_overrides'

import './stylesheets/dropzone/basic.scss'
import './stylesheets/dropzone/dropzone.scss'
import './stylesheets/dropzone/overwrites'

import './stylesheets/frappe-charts'
import './stylesheets/trix'
import './stylesheets/sortable'
import './stylesheets/custom'

import './cookie-notice.js'
import './feature-box-toggle'
import './flyout-menu'
import './howto-sites'
import './marketing-site'
import './table-of-contents'
import './sortable'
import './trix-editor-overrides'
import './youtube-embed'

import 'alpinejs'

import 'controllers'

import Choices from 'choices.js';

import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de.js';
require("flatpickr/dist/themes/airbnb.css");

import tinypicker from 'tiny-picker';
window.TinyPicker = tinypicker;

import Picker from 'vanilla-picker';
window.Picker = Picker;

import { CountUp } from 'countup.js';
window.CountUp = CountUp;

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js';
window.chart = Chart;

import copy from 'copy-to-clipboard';
window.copy = copy;

import lozad from 'lozad';

document.addEventListener("turbolinks:load", function() {
  reattach_events();

  const observer = lozad();
  observer.observe();
});

var attach_choices = function() {
  var element = document.querySelector('select.primary_category');
  if (element) new Choices(element);

  var element = document.querySelector('select.secondary_category');
  if (element) new Choices(element);

  var element = document.querySelector('select.language');
  if (element) new Choices(element);

  var element = document.querySelector('select.publishing_timezone');
  if (element) new Choices(element);

  var element = document.querySelector('select.episode_type');
  if (element) new Choices(element);

  var element = document.querySelector('select.program_type');
  if (element) new Choices(element);

  var element = document.querySelector('select.title_mode');
  if (element) new Choices(element);

  var element = document.querySelector('select.episodes_mode');
  if (element) new Choices(element);

  var element = document.querySelector('select#profile_country_code');
  if (element) new Choices(element, { shouldSort: false });

  var element = document.querySelector('select#profile_subscription');
  if (element) new Choices(element);

  var analytics_episodes = document.querySelector('select.analytics_episodes');
  var analytics_episodes_choices = null;
  if (analytics_episodes) {
    analytics_episodes_choices = new Choices(analytics_episodes, { shouldSort: false });
  }
  window.analytics_episodes_choices = analytics_episodes_choices;

  var analytics_source = document.querySelector('select.analytics_source');
  if (analytics_source) {
    new Choices(analytics_source, { shouldSort: false });
  }

  var element = document.querySelector('select.dai_snippet_pre_roll');
  if (element) {
    new Choices(element);
    element.onchange = (event) => {
       var button = document.querySelector('input.toggle_dai_campaign');
       button.classList.add('opacity-50');
       button.classList.add('cursor-not-allowed');
       button.removeAttribute('data');
       button.setAttribute('disabled', 'disabled');
    }
  }

  var element = document.querySelector('select.dai_snippet_post_roll');
  if (element) {
    new Choices(element);
    element.onchange = (event) => {
       var button = document.querySelector('input.toggle_dai_campaign');
       button.classList.add('opacity-50');
       button.classList.add('cursor-not-allowed');
       button.removeAttribute('data');
       button.setAttribute('disabled', 'disabled');
    }
  }

  var element = document.querySelector('select.dai_snippet_mid_roll_01');
  if (element) {
    new Choices(element);
    element.onchange = (event) => {
       var button = document.querySelector('input.toggle_dai_campaign');
       button.classList.add('opacity-50');
       button.classList.add('cursor-not-allowed');
       button.removeAttribute('data');
       button.setAttribute('disabled', 'disabled');
    }
  }

  var element = document.querySelector('select.dai_snippet_mid_roll_02');
  if (element) {
    new Choices(element);
    element.onchange = (event) => {
       var button = document.querySelector('input.toggle_dai_campaign');
       button.classList.add('opacity-50');
       button.classList.add('cursor-not-allowed');
       button.removeAttribute('data');
       button.setAttribute('disabled', 'disabled');
    }
  }

  var element = document.querySelector('select.dai_snippet_mid_roll_03');
  if (element) {
    new Choices(element);
    element.onchange = (event) => {
       var button = document.querySelector('input.toggle_dai_campaign');
       button.classList.add('opacity-50');
       button.classList.add('cursor-not-allowed');
       button.removeAttribute('data');
       button.setAttribute('disabled', 'disabled');
    }
  }

  var element = document.querySelector('select.dai_snippet_mid_roll_04');
  if (element) {
    new Choices(element);
    element.onchange = (event) => {
       var button = document.querySelector('input.toggle_dai_campaign');
       button.classList.add('opacity-50');
       button.classList.add('cursor-not-allowed');
       button.removeAttribute('data');
       button.setAttribute('disabled', 'disabled');
    }
  }

  var element = document.querySelector('select.dai_snippet_mid_roll_05');
  if (element) {
    new Choices(element);
    element.onchange = (event) => {
       var button = document.querySelector('input.toggle_dai_campaign');
       button.classList.add('opacity-50');
       button.classList.add('cursor-not-allowed');
       button.removeAttribute('data');
       button.setAttribute('disabled', 'disabled');
    }
  }
};

// TODO: localise dateFormat and days
var attach_flatpickr = function() {
  flatpickr('#podcast_published_at', {
    locale: German,
    enableTime: true,
    dateFormat: 'd.m.Y H:i'
  });

  var episode_publishing_date = document.querySelector('#episode_publishing_date');
  var episode_publishing_date_flatpickr = null;
  if (episode_publishing_date) {
    episode_publishing_date_flatpickr = flatpickr('#episode_publishing_date', {
      locale: German,
      enableTime: true,
      allowInput: true,
      dateFormat: 'd.m.Y H:i'
    });
  }
  window.episode_publishing_date_flatpickr = episode_publishing_date_flatpickr;

  if (document.getElementById('start_date')) {
    var initTinyPicker = function() {
      new TinyPicker({
        firstBox: document.getElementById('start_date'),
        lastBox: document.getElementById('end_date'),
        startDate: new Date(document.getElementById('start_date').getAttribute('date')),
        endDate: new Date(document.getElementById('end_date').getAttribute('date')),
        allowPast: true,
        days: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        local: 'de-DE',
        localOpts: { year: 'numeric', month: '2-digit', day: '2-digit' },
        success: function(startDate, endDate) {
          document.getElementById('query').submit();
        }
      }).init();
    }
    initTinyPicker();
    window.initTinyPicker = initTinyPicker;
  }
}

var attach_autoresize = function() {
  document.querySelectorAll('[data-autoresize]').forEach(function (element) {
    element.style.boxSizing = 'border-box';
    var offset = element.offsetHeight - element.clientHeight;
    element.addEventListener('input', function (event) {
      event.target.style.height = 'auto';
      event.target.style.height = event.target.scrollHeight + offset + 'px';
    });
    element.removeAttribute('data-autoresize');
  });
}

var reattach_events = function() {
  attach_choices();
  attach_flatpickr();
  attach_autoresize();
};

var add_keyword = function(keyword) {
  var el = document.getElementById('episode_keywords');
  if (el.value == '') {
    el.value = keyword;
  } else {
    el.value = el.value + ', '+ keyword;
  }
  document.getElementById('episode_keywords').dispatchEvent(new Event('input', { bubbles: true }));
}
window.add_keyword = add_keyword;
