document.addEventListener("DOMContentLoaded", function() {
  document.addEventListener("turbolinks:load", function() {
    if (document.getElementById('howto')) {
      const tables = document.getElementsByTagName('table');

      for (let i = 0; i < tables.length; i++) {
        let overflowWrapper = document.createElement('div');
        overflowWrapper.classList.add('overflow-x-auto');

        tables[i].parentNode.insertBefore(overflowWrapper, tables[i]);
        overflowWrapper.appendChild(tables[i]);
      }
    }
  });
});
