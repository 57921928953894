window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Bitte nur jpeg oder png Dateien einfügen")
  }
})

window.addEventListener("trix-file-accept", function(event) {
  const maxFileSize = 1024 * 1024 // 1MB
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("Die maximale Dateigröße beträgt 1MB")
  }
})
