import { Controller } from '@hotwired/stimulus'
import 'ninja-keys'

export default class extends Controller {
  connect() {
    this.element.data = []

    if (gon.podcast) {
      let timeout = null;
      window.ninja = this.element

      this.element.addEventListener('change', (event) => {
        clearTimeout(timeout);

        Turbolinks.controller.adapter.progressBar.setValue(0);
        Turbolinks.controller.adapter.progressBar.show();

        timeout = setTimeout(function() {
          fetch('/command_palette/search?id=' + gon.podcast.slug + '&q=' + event.detail.search)
            .then(response => response.json())
            .then(json => {
              let data = window.ninja.data.filter(function(value) {
                return !value.id.startsWith('search-result-')
              });
              window.ninja.data = data

              for (let index = 0; index < json.episodes.length; ++index) {
                const episode = json.episodes[index];
                let episode_id = episode.id
                let episode_title = episode.title
                let episode_keywords = episode.keywords
                let episode_url = episode.url

                if (episode_id != '') {
                  window.ninja.data.push({
                    id: 'search-result-' + episode_id,
                    slug: index + '-' + episode_id,
                    title: episode_title,
                    keywords: episode_keywords,
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                           <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                           </svg>`,
                    section: 'Gefundene Episoden',
                    handler: () => {
                      Turbolinks.visit(episode_url);
                    }
                  })
                } else {
                  window.ninja.data.push({
                    id: 'search-result-' + episode_id,
                    slug: '',
                    title: episode_title,
                    keywords: episode_keywords,
                    section: 'Gefundene Episoden'
                  })
                }
              }
              const arrayUniqueByKey = [...new Map(window.ninja.data.map(item => [item['id'], item])).values()];
              arrayUniqueByKey.sort((a, b) => (a.slug > b.slug) ? 1 : -1)
              window.ninja.data = arrayUniqueByKey

              Turbolinks.controller.adapter.progressBar.hide();
              Turbolinks.controller.adapter.progressBar.setValue(100);
            }
          )
        }, 200);
      })
    }

    if (gon.podcast) {
      this.element.data.push(
        {
          id: 'New Episode',
          title: `Neue Episode anlegen`,
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
                 </svg>`,
          section: `Podcast ${gon.podcast.title}`,
          handler: () => {
            Turbolinks.visit(`/podcasts/${gon.podcast.slug}/episodes/new`);
          },
        },
      )

      this.element.data.push(
        {
          id: 'rss feed',
          title: `RSS-Feed`,
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                 </svg>`,
          section: `Podcast ${gon.podcast.title}`,
          handler: () => {
            window.open(`${gon.podcast.feed}`, name='_blank');
          },
        },
      )

      if (gon.podcast.spotify) {
        this.element.data.push(
          {
            id: 'Spotify Statistics',
            title: `Spotify-Statistiken`,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 30 30" stroke="currentColor" stroke-width="2">
                   <path d="M15,3C8.4,3,3,8.4,3,15s5.4,12,12,12s12-5.4,12-12S21.6,3,15,3z M19.731,21c-0.22,0-0.33-0.11-0.55-0.22 c-1.65-0.991-3.74-1.54-5.94-1.54c-1.21,0-2.53,0.22-3.63,0.44c-0.22,0-0.44,0.11-0.55,0.11c-0.44,0-0.77-0.33-0.77-0.77 s0.22-0.77,0.66-0.77c1.43-0.33,2.861-0.55,4.401-0.55c2.53,0,4.84,0.66,6.82,1.76c0.22,0.22,0.44,0.33,0.44,0.77 C20.39,20.78,20.06,21,19.731,21z M20.94,17.921c-0.22,0-0.44-0.11-0.66-0.22c-1.87-1.21-4.511-1.87-7.37-1.87 c-1.43,0-2.751,0.22-3.74,0.44c-0.22,0.11-0.33,0.11-0.55,0.11c-0.55,0-0.881-0.44-0.881-0.881c0-0.55,0.22-0.77,0.77-0.991 c1.32-0.33,2.641-0.66,4.511-0.66c3.08,0,5.94,0.77,8.361,2.2c0.33,0.22,0.55,0.55,0.55,0.881 C21.82,17.48,21.491,17.921,20.94,17.921z M22.37,14.4c-0.22,0-0.33-0.11-0.66-0.22c-2.2-1.21-5.39-1.98-8.47-1.98 c-1.54,0-3.19,0.22-4.621,0.55c-0.22,0-0.33,0.11-0.66,0.11c-0.66,0.111-1.1-0.44-1.1-1.099s0.33-0.991,0.77-1.1 C9.39,10.22,11.26,10,13.24,10c3.41,0,6.93,0.77,9.681,2.2c0.33,0.22,0.66,0.55,0.66,1.1C23.471,13.96,23.03,14.4,22.37,14.4z"/>
                   </svg>`,
            section: `Podcast ${gon.podcast.title}`,
            handler: () => {
              window.open(`${gon.podcast.spotify}`, name='_blank')
            },
          },
        )
      }

      if (gon.podcast.website) {
        this.element.data.push(
          {
            id: 'Podcast Website',
            title: `Podcast-Webseite`,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                   <path stroke-linecap="round" stroke-linejoin="round" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                   </svg>`,
            section: `Podcast ${gon.podcast.title}`,
            handler: () => {
              window.open(`${gon.podcast.website}`, name='_blank');
            },
          },
        )
      }

      this.element.data.push(
        {
          id: 'Statistics',
          title: `Statistiken`,
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                 <path stroke-linecap="round" stroke-linejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                 </svg>`,
          section: `Podcast ${gon.podcast.title}`,
          handler: () => {
            Turbolinks.visit(`/podcasts/${gon.podcast.slug}/analytics`);
          },
        },
      )
    }

    if (gon.podcasts) {
      for (let podcast = 0; podcast < gon.podcasts.length; podcast++) {
        let podcast_title = gon.podcasts[podcast][0]
        let podcast_slug = gon.podcasts[podcast][1]

        this.element.data.push(
          {
            id: podcast_title,
            title: podcast_title,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                   <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                   </svg>`,
            section: 'Meine Podcasts',
            handler: () => {
              Turbolinks.visit(`/podcasts/${podcast_slug}/episodes`);
            },
          }
        )
      }
    }

    this.element.data.push(
      {
        id: 'Dashboard',
        title: 'Alle Podcasts anzeigen',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
               <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
               </svg>`,
        section: 'Meine Podcasts',
        handler: () => {
          Turbolinks.visit('/podcasts')
        },
      }
    )

    this.element.data.push(
      {
        id: 'Help',
        title: 'Hilfe anzeigen',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
               <path stroke-linecap="round" stroke-linejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
               </svg>`,
        section: 'Hilfe & Infos',
        handler: () => {
          Turbolinks.visit('/blog/podcast-starter-guide')
        },
      },
      {
        id: 'Thomann',
        title: 'Equipment-Einkaufsliste bei Thomann.de',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
               <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
               </svg>`,
        section: 'Hilfe & Infos',
        handler: () => {
          window.open('https://thmn.to/thocf/hkm42qf8l6', name='_blank')
        },
      },
      {
        id: 'YouTube',
        title: 'LetsCast.fm YouTube-Tutorials',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 30 30" stroke="currentColor" stroke-width="2">
               <path d="M 15 4 C 10.814 4 5.3808594 5.0488281 5.3808594 5.0488281 L 5.3671875 5.0644531 C 3.4606632 5.3693645 2 7.0076245 2 9 L 2 15 L 2 15.001953 L 2 21 L 2 21.001953 A 4 4 0 0 0 5.3769531 24.945312 L 5.3808594 24.951172 C 5.3808594 24.951172 10.814 26.001953 15 26.001953 C 19.186 26.001953 24.619141 24.951172 24.619141 24.951172 L 24.621094 24.949219 A 4 4 0 0 0 28 21.001953 L 28 21 L 28 15.001953 L 28 15 L 28 9 A 4 4 0 0 0 24.623047 5.0546875 L 24.619141 5.0488281 C 24.619141 5.0488281 19.186 4 15 4 z M 12 10.398438 L 20 15 L 12 19.601562 L 12 10.398438 z"/>
               </svg>`,
        section: 'Hilfe & Infos',
        handler: () => {
          window.open('https://youtube.com/letscastfm', name='_blank')
        },
      },
      {
        id: 'Slack',
        title: 'LetsCast.fm Slack Channel',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 30 30" stroke="currentColor" stroke-width="2">
               <path d="M 16.644531 2.0058594 C 16.365063 1.9841719 16.077422 2.0168281 15.794922 2.1113281 C 14.664922 2.4893281 14.053641 3.71375 14.431641 4.84375 L 15.324219 7.5117188 L 10.236328 9.2128906 L 9.3828125 6.6601562 C 9.0048125 5.5301563 7.7803906 4.9208281 6.6503906 5.2988281 C 5.5193906 5.6768281 4.9110625 6.8992969 5.2890625 8.0292969 L 6.1425781 10.582031 L 3.4746094 11.474609 C 2.3446094 11.852609 1.7333281 13.075078 2.1113281 14.205078 C 2.4893281 15.335078 3.71375 15.946359 4.84375 15.568359 L 7.5117188 14.675781 L 9.2128906 19.763672 L 6.6601562 20.617188 C 5.5301563 20.995187 4.9208281 22.219609 5.2988281 23.349609 C 5.6768281 24.480609 6.8992969 25.088938 8.0292969 24.710938 L 10.582031 23.857422 L 11.474609 26.525391 C 11.852609 27.655391 13.075078 28.266672 14.205078 27.888672 C 15.335078 27.510672 15.945359 26.28625 15.568359 25.15625 L 14.675781 22.488281 L 19.763672 20.785156 L 20.617188 23.339844 C 20.995187 24.469844 22.219609 25.079172 23.349609 24.701172 C 24.480609 24.323172 25.089891 23.100703 24.712891 21.970703 L 23.857422 19.416016 L 26.525391 18.523438 C 27.655391 18.145437 28.266672 16.922969 27.888672 15.792969 C 27.510672 14.662969 26.28625 14.053641 25.15625 14.431641 L 22.488281 15.324219 L 20.787109 10.236328 L 23.339844 9.3828125 C 24.469844 9.0048125 25.079172 7.7803906 24.701172 6.6503906 C 24.323172 5.5203906 23.100703 4.9110625 21.970703 5.2890625 L 19.417969 6.1425781 L 18.525391 3.4746094 C 18.241891 2.6271094 17.482937 2.0709219 16.644531 2.0058594 z M 16.693359 11.605469 L 18.394531 16.693359 L 13.306641 18.394531 L 11.605469 13.306641 L 16.693359 11.605469 z"/>
               </svg>`,
        section: 'Hilfe & Infos',
        handler: () => {
          Turbolinks.visit('/slack')
        },
      },
      {
        id: 'Profile',
        title: 'Profil anzeigen',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
               <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
               </svg>`,
        section: 'Profil',
        handler: () => {
          Turbolinks.visit('/user/profile/edit')
        },
      },
      {
        id: 'Invoices',
        title: 'Rechnungen anzeigen',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15px" style="margin-right: 5px;" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
               <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
               </svg>`,
        section: 'Profil',
        handler: () => {
          Turbolinks.visit('/user/invoices')
        },
      }
    )
  }
}
