document.addEventListener('turbolinks:load', () => {
  if(document.getElementById('feature-box-toggle')) {
    const toggle = document.getElementById('feature-box-toggle');
    const featureBox = document.getElementById('feature-box');
    const featureOverlay = document.getElementById('feature-overlay');

    toggle.addEventListener('click', () => {

      setTimeout(() => {
        featureBox.style.maxHeight = '600rem';
        featureBox.style.height = 'auto';
        featureOverlay.remove();
      }, 250);

      featureOverlay.classList.add('opacity-0');
    });
  }
});
