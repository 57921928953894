document.addEventListener('turbolinks:load', () => {
  const pricingToggle = document.getElementById('pricing-toggle');
  let pricingToggleState = false;

  const pricingToggleNextGen = document.getElementById('pricing-toggle-next-gen');
  let pricingToggleNextGenState = false;

  const pricingToggle2023 = document.getElementById('pricing-toggle-2023');
  let pricingToggle2023State = false;

  const pricingCompany = document.getElementById('pricing-company');
  const pricingPrivate = document.getElementById('pricing-private');

  let pricingToggleTaxes = false;

  const pricingStructure = (tier, amount, type, saving) => `
    <div class='flex items-center justify-center'>
      <span class="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
        <span class="font-extrabold">
          ${amount}
        </span>
        <span class="mt-2 ml-2 text-4xl font-medium">
          €
        </span>
      </span>
      ${tier === 'Early Access' ? `
        <div class='hidden md:inline'>
          <div class='text-red-700' style='float: none; width: ${type === 'Jahr' ? '165px' : '120px'}; border-bottom: solid 3px; position: absolute; transform: rotate(-10deg); margin-top: .5rem;'>
            &nbsp;
          </div>
          <span class='px-3 flex items-start text-6xl leading-none tracking-tight text-gray-400 sm:text-6xl'>
            <span class='font-bold'>
              ${type === 'Jahr' ? `199` : `19`}
            </span>
            <span class='mt-2 ml-2 text-4xl font-medium'>
              €
            </span>
          </span>
        </div>
        <span class="text-2xl leading-7 font-medium text-gray-400">` :
        `<span class="text-xl leading-7 font-medium text-gray-400">`
      }
      / ${type}
      </span>
    </div>
    ${tier !== 'Early Access' ? `
      <div class='mt-4 text-gray-600 text-center font-bold'>
        ${type === 'Jahr' ? `Spare ${saving} €` : '&nbsp;'}
      </div>` :
      ``
    }
  `;

  if (pricingToggle) {
    pricingToggle.addEventListener('click', function() {
      const earlyAccessPricing = document.getElementById('early-access');
      const professionalPricing = document.getElementById('professional');
      const enterprisePricing = document.getElementById('enterprise');


      const pricing = [
        { element: earlyAccessPricing, name: 'Early Access', yearly: '49', monthly: '5', saving: '11' },
        { element: professionalPricing, name: 'Professional', yearly: '549', monthly: '49', saving: '39' },
        { element: enterprisePricing, name: 'Enterprise', yearly: '1.099', monthly: '99', saving: '89' },
      ];

      pricingToggleState = !pricingToggleState;

      if (pricingToggleState) {
        pricing.forEach(price => {
          price.element.innerHTML = pricingStructure(price.name, price.yearly, 'Jahr', price.saving);
        });
      } else {
        pricing.forEach(price => {
          price.element.innerHTML = pricingStructure(price.name, price.monthly, 'Monat', price.saving);
        });
      }
    });
  }

  const pricingNextGenStructure = (tier, amount, type, saving) => `
    <div class='flex items-center justify-center'>
      <span class="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
        <span class="font-extrabold">
          ${amount}
        </span>
        <span class="mt-2 ml-2 text-4xl font-medium">
          €
        </span>
      </span>
      <span class="text-2xl leading-8 text-gray-400">
      / ${type}
      </span>
    </div>
    <div class='mt-4 text-gray-600 text-center font-bold'>
      ${type === 'Jahr' ? `Zwei Monate kostenfrei` : '&nbsp;'}
    </div>
  `;

  if (pricingToggleNextGen) {
    pricingToggleNextGen.addEventListener('click', function() {
      const starterPricing = document.getElementById('starter');
      const hobbyPricing = document.getElementById('hobby');
      const professionalPricing = document.getElementById('professional');

      const pricing = [
        { element: starterPricing, name: 'Starter', yearly: '49', monthly: '5', saving: '11' },
        { element: hobbyPricing, name: 'Hobby', yearly: '99', monthly: '10', saving: '21' },
        { element: professionalPricing, name: 'Professional', yearly: '250', monthly: '25', saving: '50' },
      ];

      pricingToggleNextGenState = !pricingToggleNextGenState;

      if (pricingToggleNextGenState) {
        pricing.forEach(price => {
          price.element.innerHTML = pricingNextGenStructure(price.name, price.yearly, 'Jahr', price.saving);
        });
      } else {
        pricing.forEach(price => {
          price.element.innerHTML = pricingNextGenStructure(price.name, price.monthly, 'Monat', price.saving);
        });
      }
    });
  }

  const pricing2023Structure = (tier, amount, type, saving) => `
    <div class="w-full">
      <span class="text-4xl font-bold tracking-tight text-gray-900">${amount} €</span>
      <span class=" text-sm leading-6 text-gray-600">/ ${type}</span>
    </div>
  `;

  if (pricingToggle2023) {
    const starterPricing = document.getElementById('starter');
    const hobbyPricing = document.getElementById('hobby');
    const professionalPricing = document.getElementById('professional');

    const pricing = [
      { element: starterPricing, name: 'Starter', yearly: '89', monthly: '9', yearly_with_taxes: '105,91', monthly_with_taxes: '10,71' },
      { element: hobbyPricing, name: 'Hobby', yearly: '189', monthly: '19', yearly_with_taxes: '224,91', monthly_with_taxes: '22,61' },
      { element: professionalPricing, name: 'Professional', yearly: '489', monthly: '49', yearly_with_taxes: '581,91', monthly_with_taxes: '58,31' },
    ];

    pricingToggle2023.addEventListener('click', function() {
      pricingToggle2023State = !pricingToggle2023State;
      togglePricing();
    });

    if (pricingCompany && pricingPrivate) {
      pricingPrivate.addEventListener('click', function() {
        pricingToggleTaxes = true;
        changeTabClasses(pricingPrivate, pricingCompany);
        togglePricing();
      });

      pricingCompany.addEventListener('click', function() {
        pricingToggleTaxes = false;
        changeTabClasses(pricingCompany, pricingPrivate);
        togglePricing();
      });
    }

    const changeTabClasses = (activePricing, inactivePricing) => {
      activePricing.classList.add("bg-teal-400");
      activePricing.classList.add("text-white");
      activePricing.classList.remove("bg-gray-100");
      activePricing.classList.remove("text-gray-500");

      inactivePricing.classList.remove("bg-teal-400");
      inactivePricing.classList.remove("text-white");
      inactivePricing.classList.add("bg-gray-100");
      inactivePricing.classList.add("text-gray-500");
    }

    const togglePricing = () => {
      let amount = (price) => {
        if (pricingToggleTaxes) {
          return pricingToggle2023State ? price.yearly_with_taxes : price.monthly_with_taxes
        } else {
          return pricingToggle2023State ? price.yearly : price.monthly
        }
      }

      let type = () => {
        if (pricingToggleTaxes) {
          return pricingToggle2023State ? 'Jahr inkl. MwSt' : 'Monat inkl. MwSt'
        } else {
          return pricingToggle2023State ? 'Jahr zzgl. MwSt' : 'Monat zzgl. MwSt'
        }
      }

      pricing.forEach(price => {
        price.element.innerHTML = pricing2023Structure(price.name, amount(price), type());
      });
    }
  }
});
