document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('flyout-nav')) {
    const flyoutMenu = document.getElementById('flyout-nav');
    const flyoutContent = document.getElementById('flyout-content');
    let flyoutMenuState = false;

    flyoutMenu.addEventListener('click', () => {
      toggleFlyoutMenu();
    });

    const toggleFlyoutMenu = () => {
      if  (!flyoutMenuState) {
        flyoutMenu.classList.remove("hidden");
        flyoutContent.setAttribute("class", "absolute");
      }

      if (flyoutMenuState) {
        flyoutMenu.classList.remove("absolute");
        flyoutContent.setAttribute("class", "hidden");
      }

      flyoutMenuState = !flyoutMenuState;
    }
  }
});
