import Dropzone from "../packs/dropzone.js";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers";

export default class extends Controller {
  static targets = ["input", "success", "warning"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  openUploadHelp(event) {
    event.stopImmediatePropagation();
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      if (this.element.classList.contains('dropzone-inline') && document.getElementById('episode_chapters_import')) {
        document.getElementById('upload-help-container').style.display = 'none';
        document.getElementsByClassName('dz-progress')[0].style.display = 'none';
        document.getElementsByClassName('dz-image')[0].style.height = '100px';
        readFile(file);  
      } else {
        setTimeout(() => {
          file.accepted && createDirectUploadController(this, file).start();
        }, 500);
      }
    });

    this.dropZone.on("removedfile", file => {
      if (file.controller) {
        removeElement(file.controller.hiddenInput);
        file.controller.hideMessage(this.successTarget);
        file.controller.hideSubmitWarning(file.controller.submitWarning);
      }
    });

    this.dropZone.on("canceled", file => {
      if (file.controller) {
        file.controller.xhr.abort();
        file.controller.hideMessage(this.warningTarget);
        file.controller.hideSubmitWarning(file.controller.submitWarning);
      }
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 512;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  submitWarning = document.getElementsByClassName("submit-warning");

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.showMessage(this.source.warningTarget);
    this.hideMessage(this.source.successTarget);
    this.showSubmitWarning(this.submitWarning);
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  showMessage(target) {
    target.style.display = "block";
  }

  hideMessage(target) {
    target.style.display = "none";
  }

  showSubmitWarning(warnings) {
    Array.from(warnings).forEach(warning => {
      warning.style.display = "block";
    });
  }

  hideSubmitWarning(warnings) {
    Array.from(warnings).forEach(warning => {
      warning.style.display = "none";
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
    this.hideMessage(this.source.warningTarget);
    this.showMessage(this.source.successTarget);
    this.hideSubmitWarning(this.submitWarning);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false
  });
}

function readFile(file) {
  let textarea = document.getElementById('episode_chapters_import')

  let reader = new FileReader();

  reader.onload = (e) => {
    const file = e.target.result;
    const lines = file.split(/\r\n|\n/);

    textarea.value = lines.join('\n');
    resizeTextarea(textarea);
  };

  reader.readAsText(file);
}

function resizeTextarea(o) {
  o.style.height = '200px';
  o.style.height = o.scrollHeight + 12 + 'px';
}
