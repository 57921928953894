document.addEventListener("turbolinks:load", function() {
  if (document.getElementById('table-of-contents')) {

    if (location.hash == '') {
      // history.pushState(null, null, location.pathname); // Prevents reload on back button
    }

    const articleContent = document.getElementById('article-content');
    const tableOfContents = document.getElementById('table-of-contents');
    const headings = getHeadings();

    function getHeadings() {
      if (document.getElementsByClassName('how-to').length === 0) {
        return articleContent.querySelectorAll('h2');
      } else {
        return articleContent.querySelectorAll('h3');
      }
    }

    if (tableOfContents.children.length <= 0) {
      for (const heading of headings) {
        if (heading.innerText === "Inhaltsverzeichnis") { continue; }

        const newId = heading.innerText.replace(/[\.\:\–]/g, '').split(/[\-_ ]/).join('-').toLowerCase();

        var a = document.createElement('a');
        a.name = newId;
        a.id = newId;
        heading.before(a);

        tableOfContents.innerHTML += `<li>
                                        <a href="#${a.id}"
                                           class="font-medium text-teal-400 hover:text-teal-600"
                                           data-turbolinks="false">${heading.innerText}
                                        </a>
                                      </li>`;
      }
    }
  }
});
