import Cookies from 'js-cookie';

document.addEventListener('turbolinks:load', () => {
  if (document.getElementsByClassName('youtube')) {
    let youtubeElements = document.getElementsByClassName('youtube');

    for (let i = 0; i < youtubeElements.length; i++) {
      let element = youtubeElements[i];
      let youtubeId = element.dataset.youtubeId;
      let thumbnail = element.dataset.thumbnail;

      if (get_cookie() === 'true') {
        loadPlayer(element, youtubeId);
      } else {
        loadConsentBox(element, youtubeId, thumbnail);
      }
    }
  }

  function loadPlayer(element, youtubeId) {
    element.innerHTML = `<iframe
                          src="https://www.youtube-nocookie.com/embed/${youtubeId}"
                          frameborder="0"
                          class="rounded-lg mx-auto z-50 w-full h-56 md:h-full"
                          allow="autoplay"
                          style="max-width: 560px; max-heigth: 315px;"
                          allowfullscreen>
                         </iframe>`
  }

  function loadConsentBox(element, youtubeId, thumbnail) {
    element.innerHTML = `
      <div class="relative mx-auto w-80 md:w-full h-full rounded-lg shadow-lg lg:max-w-md" style="max-width: 560px">
        <div class="text-center relative block w-full h-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
          <img class="w-full h-56 md:h-80" alt="LetsCast.fm YouTube Consent Thumbnail" src="${thumbnail}" loading="lazy" style="filter: blur(0.5rem);" />
          <div class="absolute inset-0 w-full h-full flex items-center justify-center opacity-75" style="background-color: #000000D9;"></div>
          <div class="absolute inset-0 w-full h-full flex items-center justify-center">
            <div class="flex flex-col">
              <p class="text-white my-4 px-2">
                Für unsere Videos laden wir externe Inhalte von YouTube.
                Bitte klicke auf den unteren Button, wenn Du damit einverstanden bist.
                Weitere Infos findest Du in unserer <a href="/privacy#youtube" target="_blank" class="underline">Datenschutzerklärung</a>.
              </p>
              <button class="consent-button bg-teal-400 px-4 py-2 mb-4 text-white rounded font-medium w-32 mx-auto">
                Video laden
              </button>
            </div>
          </div>
        </div>
      </div>
    `

    let consentButton = element.getElementsByClassName('consent-button')[0];

    consentButton.addEventListener('click', function () {
      loadPlayer(element, youtubeId);
      set_cookie(true);
    });
  }

  function get_cookie() {
    return Cookies.get('youtube_consent');
  };

  function set_cookie(param) {
    Cookies.set('youtube_consent', param, {
      path: '/',
      expires: 90
    });
  }
});
