import Cookies from 'js-cookie';
import { LoadChat } from './chat'
import { AddGoogleAds } from './google_ads'

document.addEventListener("DOMContentLoaded", function() {
  document.addEventListener('turbolinks:load', () => {
    // Cookie Consent Container
    var body, cookie_notice_container, accept_cookie_button, decline_cookie_button, get_cookie, show_cookie_box, hide_cookie_box, set_cookie, chat_placeholder;

    get_cookie = function() {
      return Cookies.get('cookies_accepted');
    };

    set_cookie = function(param) {
      Cookies.set('cookies_accepted', param, {
        path: '/',
        expires: 90
      });
      hide_cookie_box();
    }

    show_cookie_box = function() {
      cookie_notice_container.style.display = 'block';
    }

    hide_cookie_box = function() {
      cookie_notice_container.style.display = 'none';
    }

    cookie_notice_container = document.querySelector('.cookie-notice');

    if (cookie_notice_container) {
      accept_cookie_button = document.querySelector('.accept-cookie');
      decline_cookie_button = document. querySelector('.decline-cookie');

      if (document.querySelector('#chat-placeholder')) {
        chat_placeholder = document.querySelector('#chat-placeholder');
        chat_placeholder.addEventListener('click', function() {
          if (get_cookie() === 'true') {
            chat_placeholder.remove();
            LoadChat();
          } else {
            show_cookie_box();
            cookie_notice_container.classList.add('shake-effect');
            setTimeout(
              () => cookie_notice_container.classList.remove('shake-effect'),
              1000
            );
          }
        });
      }

      if (get_cookie() === undefined) {
        show_cookie_box();
      } else if (get_cookie() === 'true') {
        // AddGoogleAds();
      }

      accept_cookie_button.addEventListener('click', function() {
        if (get_cookie() === 'false') {
          chat_placeholder.remove();
          LoadChat();
        }
        // AddGoogleAds();
        set_cookie(true);
      });

      decline_cookie_button.addEventListener('click', function() {
        set_cookie(false);
      });
    }

    // Remove Cookies on Privacy Site
    if (document.getElementById('remove-site-cookie') && document.getElementById('remove-youtube-cookie')) {
      let removeSiteCookie = document.getElementById('remove-site-cookie');
      let removeYouTubeCookie = document.getElementById('remove-youtube-cookie');

      removeSiteCookie.addEventListener('click', () => {
        Cookies.remove('cookies_accepted');
        showNotification('Cookies gelöscht', 'Site-Cookies für LetsCast.fm wurden entfernt.');
      });

      removeYouTubeCookie.addEventListener('click', () => {
        Cookies.remove('youtube_consent');
        showNotification('Cookies gelöscht', 'YouTube-Consent-Cookie wurde entfernt.');
      });
    }

    function showNotification(title, message) {
      const timeout = setTimeout(clearNotification, 3000);

      let notification = document.getElementById('notification');
      let notificationTitle = document.getElementById('notification-title');
      let notificationMessage = document.getElementById('notification-message');

      notificationTitle.innerHTML = title;
      notificationMessage.innerHTML = message;
      notification.classList.remove('hidden');
    }

    function clearNotification() {
      let notification = document.getElementById('notification');
      notification.classList.add('hidden');
    }
  });
});
